.list-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

.container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

.product-container {
    position: relative;
    margin-top: 1em;
    margin-bottom: 1em;
    cursor: pointer;
}

.product-image {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.product-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: white;
}

.product-container:hover .product-overlay {
    opacity: 90%;
}

.text {
    font-size: 1.125em;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}