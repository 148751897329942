.feedback-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: center;
    background: white;
}

.container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: center;
    background: white;
}

.questions-data {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;

    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: center;
    background: white;
}

.question-group {
    width: 95%;
    margin-bottom: 1em;

    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
}

.answer {
    width: 100%;
}

textarea {
    width: 100%;
}

input {
    width: 100%;
}

.rating {
    width: 95%;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

.fa-star {
    cursor: pointer;
    color: grey;
    transform: scale(2);
}

.hover {
    color: yellow;
    border: 1px black;
}

